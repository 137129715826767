<style lang="scss">
.layout-wrappper {
	min-height: calc(100vh - 70px);
	background: url(../assets/tobog.jpg) no-repeat top center/cover;
	color: #fff;
}
.layout-main {
	font-size: 1.2rem;
	max-width: 1000px;
	margin: auto;
	padding-top: 15px;

	.title {
		text-transform: uppercase;
	}

	.project-ui {
		margin: 20px auto;
		min-height: 25vh;
		padding: 15px;
		text-align: center;
		box-shadow: 0 0 6px #fff;
		background-color: rgba($color: #000000, $alpha: 0.5);
		& > * {
			padding: 10px;
			line-height: 1.8rem;
		}
	}

	.link-btn {
		display: inline-block;
		padding: 6px 16px;
		text-decoration: none;
		color: #fff;
		background-color: #19be6b;
		min-width: 100px;
		border-radius: 3px;
		font-size: 16px;
		margin: 5px 10px;
	}

	.desc {
		text-align: center;
		padding-top: 30px;
	}
}
</style>

<template>
	<div class="layout-wrappper">
		<section class="layout-main">
			<div class="project-ui">
				<h3>UI-TOBOG</h3>
				<h4>A high quality UI Toolkit based on Vue.js</h4>
				<p>一套高质量的UI组件库</p>
				<a href="/ui-tobog/index.html#/alert" class="link-btn">DEMO-UI</a>
			</div>
			<div class="project-ui">
				<h3>KUI</h3>
				<h4>A high quality UI Toolkit based on Vue.js</h4>
				<p>一套高质量的UI组件库, 基于UI-TOBOG版本升级，组件更多，扩展性更好，目前支持vue2.0和vue3.0, 文档还在持续更新中</p>
				<a href="/kui/index.html#/" class="link-btn">DEMO-UI</a>
			</div>
			<div class="project-ui">
				<h3>VUE-TOBOG</h3>
				<h4>一套完整的针对性的Vue，Vuex，Vue-Router源码分析资料</h4>
				<p>
					文档深度分析了 Vue源码目录设计、源码构建开始讲起，包括数据驱动，响应式原理 ，深入全面理解Vue的实现原理，掌握源码分析技巧，牢固对Vue的使用；
					Vuex，Vue-Router设计原理等
				</p>
				<a href="/vue-tobog/doc/index.html#/" class="link-btn">DEMO-VUE</a>
			</div>
			<div class="project-ui">
				<h3>PWA（Progressive Web App）</h3>
				<h4>一套完整的针对性PWA分析资料和DEMO</h4>
				<p>PWA（Progressive Web App）是一种理念，使用多种技术来增强 web app 的功能，可以让网站的体验变得更好，能够模拟一些原生功能，比如通知推送。在移动端利用标准化框架，让网页应用呈现和原生应用相似的体验</p>
				<a href="/pwa/doc.html" class="link-btn">DEMO-DOC</a>
				<a href="https://www.tobog.cn/pwa/index.html" class="link-btn">DEMO-PWA</a>
				<a href="https://gitee.com/orientboy/pwa" class="link-btn">DEMO-PWA-CODE</a>
			</div>
            <div class="project-ui">
				<h3>RvizROS</h3>
				<h4>RvizROS 赋能数据回放场景及基于web的3D可视化交互，助力降本增效；</h4>
                <p>工具背景：RvizROS赋能数据回放,从根本上解决了数据回放效率低，过多依赖ros系统，bag包过大播放卡顿等问题，实现了无需下载bag包即时回放；</p>
				<p>工具功能：已经支持Ros1，Ros2系统，支持离线回放，在线回放，多人回放和多包回放（无需合包）等提效的核心功能</p>
				<p>扩展性：基于此框架后续可以实现模块化构建WEB 3D能力，应用到车路云核心业务及内部数据平台等可视化业务平台</p>
				<a href="https://www.tobog.cn/rviz/" class="link-btn">RvizROS</a>
			</div>
			<div class="project-ui">
				<h3>FLUTTER-TOBOG</h3>
				<h4>一套完整的Flutter组件的基本使用和分析资料</h4>
				<p>文档深度分析了 Flutter组件的使用和开发的动态demo</p>
				<p>目前正在各组件开发中更新中，后续会正式发布和开放...</p>
				<div>
					<img src="../assets/flutter1.png" alt />
					<img src="../assets/flutter2.png" alt style="vertical-align:top;" />
				</div>
			</div>
			<div class="project-ui">
				<h3>route-loader</h3>
				<h4>webpack插件：实现跨项目加载异步加载远程路由;</h4>
				<a href="https://www.npmjs.com/package/vue-cli-plugin-route-loader" class="link-btn">NPM</a>
			</div>
			<div class="project-ui">
				<h3>sync-mocks</h3>
				<h4>webpack插件：实现离线调试后台接口，通过代理缓存接口响应数据到本地，并开启本地文件代理服务</h4>
				<a href="https://www.npmjs.com/package/vue-cli-plugin-sync-mocks" class="link-btn">NPM</a>
			</div>
			<div class="project-ui">
				<h3>CLI-TOBOG</h3>
				<h4>一个开发的cli demo 可在本地建立自己的不同资源的自动初始化项目</h4>
				<a href="https://www.npmjs.com/package/cli-tobog" class="link-btn">DEMO-CODE</a>
				<div>
					<img src="../assets/cli-demo.png" alt />
				</div>
			</div>
            <div class="project-ui">
				<h3>sql-next.js</h3>
				<h4>一个基于sql.js的基础上支持异步加载 File 和 Blob 类型数据,解决了数据库过大导致sql占用过多内存性能问题</h4>
				<a href="https://www.npmjs.com/package/sql-next.js" class="link-btn">SQL.js</a>
			</div>
            <div class="project-ui">
				<h3>rosbag_next</h3>
				<h4>一个支持自动驾驶Ros1，Ros2系统的数据包解析器</h4>
				<a href="https://www.npmjs.com/package/rosbag_next" class="link-btn">RosBag</a>
			</div>
			<p class="desc">个人网站的内容一些日志文章分享，学习笔记，生活记录，一些生活图片等</p>
		</section>
	</div>
</template>




<script>
export default {

}
</script>